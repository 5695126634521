/**
 * Redirect to the admin dashboard.
 */
export default defineNuxtRouteMiddleware(async (to) => {
    const { data } = useAuth();
    const localePath = useLocalePath();
    if (!data.value?.frontend_management_access) {
        return navigateTo(localePath('/members/dashboard'));
    }

    const teamUuid = to.params.team ?? null;
    const dashboardUrl = getAdminDashboardFromProfile(data, teamUuid);

    return navigateTo(dashboardUrl);
});
